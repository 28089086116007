import { TToken, IUserSignUp } from 'types';

import * as EP from './endpoints';
import { fetcherPost } from './fetcher';

export const restSignUp = (email: string, password: string, gdprConfirmed = true) =>
  fetcherPost<IUserSignUp>(EP.REST_USERS_SIGNUP, {
    email,
    password,
    gdprConfirmed, // TODO: value should be passed from form
  });

export const restForgotPassword = (email: string) =>
  fetcherPost(EP.REST_USERS_FORGOT_PASSWORD, {
    email,
  });

export const restResetPassword = (newPassword: string, resetToken: TToken) =>
  fetcherPost(EP.REST_USERS_FORGOT_PASSWORD, {
    newPassword,
    passwordResetToken: resetToken,
  });
