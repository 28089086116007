import { useState, useEffect } from 'react';

import { restGetOrder } from 'rest';
import { IOrder, TId } from 'types';
import { log } from 'utils';

import { useActivityIndicator } from './useActivityIndicator';
import { useIsMounted } from './useIsMounted';

export interface IUseLoadOrderHook {
  loading: boolean;
  order: IOrder | null;
  reloadOrder: () => Promise<IOrder | null>;
}

/**
 * Get order by ID
 * usage: const { order, loadOrder } = useLoadOrder(token);
 */
export const useLoadOrder = (id: TId, withLoader = false): IUseLoadOrderHook => {
  const [order, setOrder] = useState<IOrder | null>(null);
  const [loading, setLoading] = useState(false);
  const { showIndicator, hideIndicator } = useActivityIndicator();
  const isMounted = useIsMounted();

  const loadOrder = async (orderId: TId): Promise<IOrder | null> => {
    setLoading(true);
    if (withLoader) showIndicator();
    try {
      const response = await restGetOrder(orderId);
      const result = response?.docs?.[0] || null;
      setOrder(result);
      return result;
    } catch (err) {
      log.debug('loadOrder:: err: ', err);
      return null;
    } finally {
      setLoading(false);
      if (withLoader) hideIndicator();
    }
  };

  const reloadOrder = async () => await loadOrder(id);

  useEffect(() => {
    if (isMounted && id) loadOrder(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isMounted]);

  return { order, reloadOrder, loading };
};
