import Stack, { StackProps } from '@mui/material/Stack';

export type TRowProps = StackProps;

export const Row: React.FC<TRowProps> = ({ children, ...stackProps }) => (
  <Stack
    direction={{ xs: 'column', sm: 'row' }}
    justifyContent='center'
    alignItems='center'
    {...stackProps}
  >
    {children}
  </Stack>
);
