import { IPaymentType, TId } from 'types';

import * as EP from './endpoints';
import { fetcherGet, fetcherPost, fetcherDelete } from './fetcher';

export const restGetPaymentTypes = () => fetcherGet<IPaymentType[]>(EP.REST_PAYMENT_TYPES);

export const restCreatePaymentType = (data: IPaymentType) =>
  fetcherPost<IPaymentType>(EP.REST_PAYMENT_TYPES, data);

export const restDeletePaymentType = (id: TId) => fetcherDelete(EP.REST_PAYMENT_TYPES_ID(id));
