import Box from '@mui/material/Box';
import useTranslation from 'next-translate/useTranslation';

import { Link } from 'components/atoms/Link';
import { Row } from 'components/atoms/Row';
import { NavSeparator } from 'components/molecules/NavSeparator';
import { getYear } from 'utils/date.utils';

export const PageFooter: React.FC = () => {
  const { t } = useTranslation('common');
  return (
    <Box
      component='footer'
      margin='auto'
      my={4}
      px={4}
      width='100%'
      textAlign='center'
      sx={{ fontSize: '14px', lineHeight: '2' }}
    >
      <Row
        direction={{
          xs: 'column',
          sm: 'row',
        }}
        sx={{ display: 'flex' }}
      >
        <Link href='/privacy-policy'>{t('privacy_policy')}</Link>
        <NavSeparator
          sx={{
            display: {
              xs: 'none',
              sm: 'block',
            },
          }}
        />
        <Link href='/terms-and-conditions'>{t('terms_conditions')}</Link>
        <NavSeparator
          sx={{
            display: {
              xs: 'none',
              sm: 'block',
            },
          }}
        />
        <Link href='/copyright-policy'>{t('copyright_policy')}</Link>
        <NavSeparator
          sx={{
            display: {
              xs: 'none',
              sm: 'block',
            },
          }}
        />
        <Link href='/cookie-policy'>{t('cookie_policy')}</Link>
      </Row>
      <div>
        &copy; {t('copyright')} {getYear()}{' '}
        <Link href='https://www.jozefbutko.com' target='_blank'>
          Jozef Butko
        </Link>
        .<br />
        {t('made_with_keyboard_brain_next')}
      </div>
    </Box>
  );
};
