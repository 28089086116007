import merge from 'lodash/merge';
import omit from 'lodash/omit';
import { useRouter } from 'next/router';
import { parse, stringify } from 'qs';

import { TObject } from 'types';
import { log, cleanObject } from 'utils';

export interface IAppQueryParams {
  page?: {
    page: string;
    limit: string;
  };
  filter?: any;
  appState?: {
    filterActive: 'true' | 'false';
  };
}

interface IUseQueryParams {
  parsedQuery: IAppQueryParams;
  isReady: boolean;
  addQuery: (newQuery: TObject, opts?: TObject, withMerge?: boolean) => IAppQueryParams;
  removeQuery: (key: string) => IAppQueryParams;
  getQueryValue: (key: string) => void;
  resetQuery: () => IAppQueryParams;
}

export const useQueryParams = (): IUseQueryParams => {
  const { push, pathname, query, isReady } = useRouter();
  const parseQuery = providedQuery => parse(stringify(providedQuery, { encode: false }));
  const parsedQuery = parseQuery(query);

  const addQuery = (newQuery: TObject, opts?: {}, withMerge = true): IAppQueryParams => {
    const newState = cleanObject(withMerge ? merge(parseQuery(query), newQuery) : newQuery);
    const newStateString = stringify(newState, { encode: false });
    const newUrl = newStateString ? `${pathname}?${newStateString}` : pathname;
    log.debug('addQuery:: newState', newState);
    log.debug('addQuery:: newUrl', newUrl);
    push(
      {
        pathname,
        query: newStateString || '',
      },
      newUrl,
      { shallow: true, ...opts }
    );
    return newState;
  };

  const removeQuery = (path: string): IAppQueryParams => {
    const newState = cleanObject(omit(parseQuery(query), path));
    const newUrl = `${pathname}?${stringify(newState, { encode: false })}`;
    log.debug('removeQuery:: newState', newState);
    log.debug('removeQuery:: newUrl', newUrl);
    push(
      {
        pathname,
        query: stringify(newState, { encode: false }),
      },
      newUrl,
      { shallow: true }
    );
    return newState;
  };

  const resetQuery = (): IAppQueryParams => {
    const newUrl = `${pathname}`;
    log.debug('removeQuery:: newUrl', newUrl);
    push(
      {
        pathname,
        query: stringify({}, { encode: false }),
      },
      newUrl,
      { shallow: true }
    );
    return {};
  };

  const getQueryValue = (key: string): any => {
    if (isReady) return query[key];
    if (typeof window !== 'undefined') {
      const values = parse(window.location.search);
      return values[key];
    }
    return null;
  };

  return { addQuery, removeQuery, getQueryValue, parsedQuery, isReady, resetQuery };
};
