import Container from '@mui/material/Container';

import { Column, TColumnProps } from 'components/atoms/Column';
import { QueryParamsReady } from 'components/molecules/QueryParamsReady';
import { PageFooter } from 'components/templates/PageFooter';
import { TFC } from 'types';

interface IPageTemplate extends TColumnProps {
  withFooter?: boolean;
}

export const PageTemplate: TFC<IPageTemplate> = ({ children, withFooter = true, ...props }) => (
  <QueryParamsReady>
    <Column minHeight='100vh' {...props}>
      <Container maxWidth='xl' fixed sx={{ pt: 2, flex: 1 }}>
        {children}
      </Container>
      {withFooter && <PageFooter />}
    </Column>
  </QueryParamsReady>
);
