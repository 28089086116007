import { useCallback, useState, useEffect } from 'react';

import { restGetOrders } from 'rest';
import { IOrder, IOrdersResponse, TObject, IOrderResponse } from 'types';
import { log, getUrlQuery, cleanObject } from 'utils';

import { useActivityIndicator } from './useActivityIndicator';
import { useIsMounted } from './useIsMounted';
import { useOrdersStore } from 'stores';

export interface IUseLoadOrdersHook {
  loading: boolean;
  orders: IOrder[] | null;
  totalDocs: number;
  stats: IOrderResponse['stats'] | null;
  reloadOrders: (
    filter: TObject,
    newPage?: number,
    newLimit?: number
  ) => Promise<IOrdersResponse | null>;
}

/**
 * Get user orders
 * usage: const { orders, loadOrders, totalDocs } = useLoadOrders(token, page, limit, withLoader);
 */
export const useLoadOrders = (
  page = 0,
  limit = 10,
  withLoader = false,
  initialFilter = {}
): IUseLoadOrdersHook => {
  const { loadedOrders, setLoadedOrders, setTotalDocs, setStats, totalDocs, stats } =
    useOrdersStore();
  const [loading, setLoading] = useState(false);
  const { showIndicator, hideIndicator } = useActivityIndicator();
  const isMounted = useIsMounted();

  const loadOrders = useCallback(
    async (filter = {}, newPage = page, newLimit = limit): Promise<IOrdersResponse | null> => {
      setLoading(true);
      if (withLoader) showIndicator();

      const reqQuery = {
        page: {
          skip: newPage * newLimit,
          limit: newLimit,
        },
        filter,
      };
      log.debug('loadOrders:: reqQuery:', reqQuery);

      try {
        const response = await restGetOrders(getUrlQuery(cleanObject(reqQuery)));
        if (!response) return null;
        setLoadedOrders(response?.docs);
        setTotalDocs(response?.total);
        setStats(response?.stats);
        log.debug('loadOrders:: response:', response);
        return response;
      } catch (err) {
        log.debug('loadOrders:: err: ', err);
        return null;
      } finally {
        setLoading(false);
        if (withLoader) hideIndicator();
      }
    },
    [showIndicator, hideIndicator, withLoader, page, limit, setLoadedOrders, setTotalDocs, setStats]
  );

  const reloadOrders = async (filter: TObject, newPage = page, newLimit = limit) =>
    await loadOrders(filter, newPage, newLimit);

  useEffect(() => {
    if (isMounted) loadOrders(initialFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted]);

  return { orders: loadedOrders, reloadOrders, loading, totalDocs, stats };
};
