import { IShop, TId } from 'types';

import * as EP from './endpoints';
import { fetcherGet, fetcherPost, fetcherDelete } from './fetcher';

export const restGetShops = () => fetcherGet<IShop[]>(EP.REST_SHOPS);

export const restCreateShop = (data: IShop) => fetcherPost<IShop>(EP.REST_SHOPS, data);

export const restDeleteShop = (id: TId) => fetcherDelete(EP.REST_SHOPS_ID(id));
