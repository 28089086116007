import { IOrderCategory, TId } from 'types';

import * as EP from './endpoints';
import { fetcherGet, fetcherPost, fetcherDelete } from './fetcher';

export const restGetOrderCategories = () => fetcherGet<IOrderCategory[]>(EP.REST_ORDER_CATEGORIES);

export const restCreateOrderCategory = (data: IOrderCategory) =>
  fetcherPost<IOrderCategory>(EP.REST_ORDER_CATEGORIES, data);

export const restDeleteOrderCategory = (id: TId) => fetcherDelete(EP.REST_ORDER_CATEGORIES_ID(id));
