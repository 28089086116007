import { IShippingCarrier } from 'types';

import * as EP from './endpoints';
import { fetcherGet, fetcherPost } from './fetcher';

export const restGetShippingCarriers = () =>
  fetcherGet<IShippingCarrier[]>(EP.REST_SHIPPING_CARRIERS);

export const restCreateShippingCarrier = (data: IShippingCarrier) =>
  fetcherPost<IShippingCarrier>(EP.REST_SHIPPING_CARRIERS, data);
