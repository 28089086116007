import { useState, useEffect } from 'react';

/**
 * Check if component is mounted
 */
export const useIsMounted = (): boolean => {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);
  return isMounted;
};
