import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface IUseMediaQueries {
  isXs: boolean;
  isSm: boolean;
  isMd: boolean;
  isLg: boolean;
  isXl: boolean;
  isDesktop: boolean;
  isTablet: boolean;
  isMobile: boolean;
}

/**
 * Use media queries hook
 *
 * MUI Breakpoints:
 * extra-small
 * xs: 0,
 * small
 * sm: 600,
 * medium
 * md: 900,
 * large
 * lg: 1200,
 * extra-large
 * xl: 1536,
 */
export const useMediaQueries = (): IUseMediaQueries => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  const isLg = useMediaQuery(theme.breakpoints.only('lg'));
  const isXl = useMediaQuery(theme.breakpoints.only('xl'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
  const isMobile = !!isXs;

  return {
    isXs,
    isSm,
    isMd,
    isLg,
    isXl,
    isDesktop,
    isTablet,
    isMobile,
  };
};
