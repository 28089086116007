import { useState, useEffect } from 'react';

import { restGetOrderEnums } from 'rest/rest.enums';
import { IOrderEnums } from 'types';
import { log } from 'utils';

import { useActivityIndicator } from './useActivityIndicator';
import { useIsMounted } from './useIsMounted';

export interface IUseLoadEnumsHook {
  loading: boolean;
  enums: IOrderEnums | null;
  loadEnums: () => Promise<IOrderEnums | null>;
}

/**
 * Order enums hook
 * usage: const { enums, loadEnums } = useLoadEnums();
 */
export const useLoadEnums = (withLoader = false): IUseLoadEnumsHook => {
  const [enums, setEnums] = useState<IOrderEnums | null>(null);
  const [loading, setLoading] = useState(false);
  const { showIndicator, hideIndicator } = useActivityIndicator();
  const isMounted = useIsMounted();

  const loadEnums = async (): Promise<IOrderEnums | null> => {
    setLoading(true);
    if (withLoader) showIndicator();
    try {
      const enumsResponse = await restGetOrderEnums();
      log.debug('loadEnums:: enumsResponse', enumsResponse);
      setEnums(enumsResponse);
      return enumsResponse;
    } catch (err) {
      log.debug('loadEnums:: err: ', err);
      return null;
    } finally {
      setLoading(false);
      if (withLoader) hideIndicator();
    }
  };

  useEffect(() => {
    if (isMounted) loadEnums();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted]);

  return { enums, loadEnums, loading };
};
