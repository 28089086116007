import Typography, { TypographyProps } from '@mui/material/Typography';

import { TFC } from 'types';

export type TText = TypographyProps;

export const Text: TFC<TText> = ({ children, variant = 'body1', ...props }) => (
  <Typography variant={variant} {...props}>
    {children}
  </Typography>
);
