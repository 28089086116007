import Box, { BoxProps } from '@mui/material/Box';

import { Separator } from 'components/atoms/Separator';

export const NavSeparator: React.FC<BoxProps> = ({ sx, ...props }) => (
  <Box {...props}>
    <Separator
      sx={{
        mx: {
          xs: 0,
          sm: 2,
        },
        ...sx,
      }}
    />
  </Box>
);
