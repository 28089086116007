import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link';
import LinkNext, { LinkProps } from 'next/link';

import { TFC } from 'types';

interface IOwnProps {
  target?: string;
  rel?: string;
}

export const Link: TFC<IOwnProps & LinkProps & MuiLinkProps> = ({
  children,
  target = '_self',
  rel,
  ...props
}) => (
  <MuiLink component={LinkNext} {...props} target={target} rel={rel} passHref>
    {children}
  </MuiLink>
);
