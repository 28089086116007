import { IOrder, TId, IOrderStatus, IOrdersResponse, IOrderResponse, TFileWithPath } from 'types';
import { log } from 'utils';

import * as EP from './endpoints';
import { fetcherGet, fetcherPost, fetcherPut, fetcherDelete } from './fetcher';

export const restGetOrderStatuses = () => fetcherGet<IOrderStatus[]>(EP.REST_ORDER_STATUSES);

export const restGetOrders = (query: string) => {
  const URL = query ? `${EP.REST_ORDERS}?${query}` : EP.REST_ORDERS;
  return fetcherGet<IOrdersResponse>(URL);
};

export const restGetOrder = (id: TId) =>
  fetcherGet<IOrderResponse>(`${EP.REST_ORDERS}?filter[_id]=${id}`);

export const restCreateOrder = (data: IOrder = {} as IOrder) =>
  fetcherPost<IOrder>(EP.REST_ORDERS, data);

export const restUpdateOrder = (data: Partial<IOrder>, id: TId) =>
  fetcherPut<IOrder>(EP.REST_ORDERS_ID(id), data);

export const restDeleteOrder = (id: TId) => fetcherDelete(EP.REST_ORDERS_ID(id));

export const restDuplicateOrder = (id: TId) =>
  fetcherPost<IOrder>(EP.REST_ORDERS_DUPLICATE(id), {});

export const restUploadFiles = (id: TId, files: TFileWithPath[]) => {
  const formData = new FormData();
  files.map(file => formData.append('files', file));
  log.debug('restUploadFiles:: going to upload files: ', formData.values());
  log.debug('restUploadFiles:: files provide: ', files);
  // TODO: handle upload errors - file duplicates etc.
  return fetcherPut<IOrder>(EP.REST_ORDER_UPLOAD_FILES(id), formData, {}, '');
};

export const restDeleteOrderFile = (id: TId, fileId: string) =>
  fetcherPut<{}>(EP.REST_ORDER_DELETE_FILE(id, fileId));

export const restRenameOrderFile = (id: TId, fileId: string, newName: string) =>
  fetcherPut<{}>(EP.REST_ORDER_RENAME_FILE(id, fileId), { customName: newName });
