import { IOrderEnums } from 'types';
import { log } from 'utils';

import { restGetOrderCategories } from './rest.orderCategories';
import { restGetOrderStatuses } from './rest.orders';
import { restGetPaymentTypes } from './rest.paymentTypes';
import { restGetShippingCarriers } from './rest.shippingCarriers';
import { restGetShops } from './rest.shops';

export const restGetOrderEnums = async (): Promise<IOrderEnums> => {
  try {
    const [orderStatuses, paymentTypes, shippingCarriers, shops, orderCategories] =
      await Promise.all([
        restGetOrderStatuses(),
        restGetPaymentTypes(),
        restGetShippingCarriers(),
        restGetShops(),
        restGetOrderCategories(),
      ]);
    return {
      orderStatuses,
      paymentTypes,
      shippingCarriers,
      shops,
      orderCategories,
    };
  } catch (err) {
    log.error(err);
    return Promise.reject(err);
  }
};
