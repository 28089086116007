import Box, { BoxProps } from '@mui/material/Box';

import { TFC } from 'types';

export type TColumnProps = BoxProps;

export const Column: TFC<BoxProps> = ({ sx, children, ...props }) => (
  <Box display='flex' flexDirection='column' sx={{ ...sx }} {...props}>
    {children}
  </Box>
);
