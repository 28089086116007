import { useCallback, useEffect } from 'react';
import { useOrdersStore } from 'stores';
import { IOrdersSummary, TNullable } from 'types';

/*
 * This hook is used to calculate the summary of selected orders.
 * It is used in the OrdersTable component.
 */
export const useSelectedOrders = (): TNullable<IOrdersSummary> => {
  const { loadedOrders, selectedOrderIds, ordersSummary, setOrdersSummary } = useOrdersStore();

  // calculate selected orders summary: totalPrice, averagePrice, totalOrders, totalShippingFee
  const calculateSelectionInfo = useCallback(
    (ids: string[]) => {
      if (!loadedOrders || !ids.length) return setOrdersSummary(null);
      const selectedOrders = loadedOrders.filter(order => ids.includes(order._id || ''));
      const totalPrice = selectedOrders.reduce((acc, order) => acc + (order.price || 0), 0);
      const averagePrice = selectedOrders.length ? totalPrice / selectedOrders.length : 0;
      const totalOrders = selectedOrders.length;
      const totalShippingFee = selectedOrders.reduce(
        (acc, order) => acc + (order.shippingCost || 0),
        0
      );

      const summary = {
        totalPrice,
        averagePrice,
        totalOrders,
        totalShippingFee,
      };
      setOrdersSummary(summary);
      return summary;
    },
    [loadedOrders, setOrdersSummary]
  );

  useEffect(() => {
    calculateSelectionInfo(selectedOrderIds);
  }, [calculateSelectionInfo, selectedOrderIds]);

  return ordersSummary;
};
